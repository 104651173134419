import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function Partners() {
  return (
    <section id="partners">
      <ul className="partners__grid">
        <li>
          <a
            href="https://www.instagram.com/SpaceMondays"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage src="../images/partners0.png" alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/PlaygroundWednesdays"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage src="../images/partners1.png" alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/SwingersNewcastle"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage src="../images/partners2.png" alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/MarketShakerNewcastle"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage src="../images/partners3.png" alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/FeralFridays"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage src="../images/partners4.png" alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/LetsPlayDartsNewcastle"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage src="../images/partners5.png" alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/FightClubNewcastle"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage src="../images/partners6.png" alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/PumphreysNewcastle"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage src="../images/partners7.png" alt="" />
          </a>
        </li>
      </ul>
    </section>
  );
}
