import React from "react";

export function Hero({ background = null, children = null }) {
  return (
    <section id="hero">
      <div className="hero__background">{background}</div>
      <div className="hero__overlay"></div>
      <div className="hero__content">{children}</div>
    </section>
  );
}
