import TicketShop from "components/TicketShop";
import { ticketShopId } from "constants/ticketShopId";
import React from "react";

export function Tickets() {
  return (
    <section id="tickets">
      <TicketShop
        scriptString={`
          <script
            src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js"
            data-fixr-theme="light"
            data-fixr-shop-id="${ticketShopId}"
          ></script>
        `}
      />
    </section>
  );
}
