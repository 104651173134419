import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function Reps() {
  return (
    <section id="reps">
      <div className="reps__text">
        <h1 className="h2">Make ££</h1>
        <p>Join the Movement and Rep with us!</p>
        <p>
          Earn the highest rates of commission and get free entry to every event
          we do! There are no fixed hours and you have the freedom to work
          entirely on your own terms!
        </p>
        <a
          href="https://reps.fixr.co/discover/organisers/36"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sign up
        </a>
      </div>
      <StaticImage className="reps__poster" src="../images/reps.jpeg" alt="" />
    </section>
  );
}
