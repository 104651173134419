import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function Fight() {
  return (
    <section id="fight">
      <StaticImage
        className="fight__background"
        src="../images/fightBackground.jpeg"
        alt=""
      />
      <div className="fight__overlay" />
      <div className="fight__content">
        <div className="fight__text">
          <h1>Sign Up to Fight</h1>
          <p>
            Do you want to get fit? Try something new? Raise money for charity
            and take part in front of your friends and family? Well signup now
            using the link in our bio and be part of something amazing today!
          </p>
          <a
            href="https://tinyurl.com/boxingsignup"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sign up
          </a>
        </div>
        <StaticImage
          className="fight__poster"
          src="../images/fightPoster.jpeg"
          alt=""
        />
      </div>
    </section>
  );
}
