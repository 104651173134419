import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function Contact() {
  return (
    <section id="contact">
      <div className="contact__text">
        <h1 className="h2">Socials / Birthdays</h1>
        <p>
          Planning a Social? Or just wanna go big for your Birthday then drop us
          a line and let's get something booked in! We've got some mad deals
          going so let us know what the plan is and we'll make it happen!
        </p>
        <p>
          Call us on{" "}
          <a className="animated_link" href="tel:+447516 679921">
            +447516 679921
          </a>
        </p>
      </div>
      <StaticImage
        className="contact__poster"
        src="../images/contact.jpeg"
        alt=""
      />
    </section>
  );
}
