import { gallery } from "constants/galleryImages";
import { useEventListener } from "hooks/useEventListener";
import { useFocusOut } from "hooks/useFocusOut";
import React, { useEffect, useRef, useState } from "react";
import IconChevronLeft from "../svgs/IconChevronLeft.svg";
import IconChevronRight from "../svgs/IconChevronRight.svg";

export function Gallery() {
  const galleryRef = useRef(null);
  const lightboxRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  function handleOpen() {
    setIsOpen(true);
    setTimeout(() =>
      galleryRef.current?.querySelectorAll("button")[6]?.focus()
    );
  }

  const [lightboxIndex, setLightboxIndex] = useState(-1);
  const lightboxIsOpen = lightboxIndex >= 0;

  useEffect(() => {
    if (lightboxIsOpen) lightboxRef.current?.querySelector("button")?.focus();
  }, [lightboxIsOpen]);

  function lightboxClose() {
    setLightboxIndex((index) => {
      galleryRef.current?.querySelectorAll("button")[index]?.focus();
      return -1;
    });
  }

  function lightboxLeft() {
    setLightboxIndex((index) => {
      index--;
      if (index < 0) index = gallery.length - 1;
      return index;
    });
  }

  function lightboxRight() {
    setLightboxIndex((index) => {
      index++;
      if (index >= gallery.length) index = 0;
      return index;
    });
  }

  useEventListener("keydown", (e) => {
    if (e.key === "Escape") lightboxClose();
    if (e.key === "ArrowLeft") lightboxLeft();
    if (e.key === "ArrowRight") lightboxRight();
  });

  useEventListener("mousedown", (e) => {
    if (!lightboxRef.current.contains(e.target)) lightboxClose();
  });

  useFocusOut(lightboxRef, lightboxClose);

  return (
    <>
      <section id="gallery" ref={galleryRef}>
        <h1 className="h2">Photo Galleries</h1>
        <ul className="gallery__grid">
          {gallery.slice(0, 6).map((Image, i) => (
            <li key={i} className="gallery__grid_item">
              <button onClick={() => setLightboxIndex(i)}>
                <Image />
              </button>
            </li>
          ))}
        </ul>
        <div className="gallery__drawer" data-open={isOpen}>
          <ul className="gallery__grid">
            {gallery.slice(6).map((Image, i) => (
              <li key={i} className="gallery__grid_item">
                <button
                  onClick={() => setLightboxIndex(6 + i)}
                  tabIndex={isOpen ? 0 : -1}
                >
                  <Image />
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="gallery__button-container">
          <a
            className="gallery__button"
            href="https://www.facebook.com/StageOneEvents/photos"
            target="_blank"
            rel="noopener noreferrer"
            aria-disabled={!isOpen}
            tabIndex={isOpen ? 0 : -1}
          >
            See more on facebook
          </a>

          <button
            className="gallery__button"
            onClick={handleOpen}
            aria-disabled={isOpen}
            tabIndex={isOpen ? -1 : 0}
          >
            See more
          </button>
        </div>
      </section>
      <aside
        className="gallery__lightbox"
        role="dialog"
        hidden={!lightboxIsOpen}
      >
        <div className="gallery__lightbox-content" ref={lightboxRef}>
          <div className="gallery__lightbox-image">
            {gallery.map((Image, i) => (
              <div key={i} aria-hidden={i !== lightboxIndex}>
                <Image />
              </div>
            ))}
          </div>
          <div className="gallery__lightbox-controls">
            <button onClick={lightboxLeft} tabIndex={lightboxIsOpen ? 0 : -1}>
              <IconChevronLeft />
            </button>
            <div />
            <span>
              {lightboxIndex + 1} / {gallery.length}
            </span>
            <div />
            <button onClick={lightboxRight} tabIndex={lightboxIsOpen ? 0 : -1}>
              <IconChevronRight />
            </button>
          </div>
        </div>
      </aside>
    </>
  );
}
