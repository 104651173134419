import { Contact } from "components/Contact";
import { Fight } from "components/Fight";
import { Gallery } from "components/Gallery";
import { Hero } from "components/Hero";
import { Partners } from "components/Partners";
import { Reps } from "components/Reps";
import { SocialIcons } from "components/SocialIcons";
import { Tickets } from "components/Tickets";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Helmet from "react-helmet";

export default function IndexPage({ location }) {
  const title = "Stage One Events";
  const description =
    "The heads behind your favourite nights out in Newcastle since 2010!";

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <Hero background={<StaticImage src="../images/hero.png" alt="" />}>
        <StaticImage
          className="header__image-mobile_only"
          src="../images/logo.png"
          alt=""
        />
        <h1 className="h3">
          The heads behind your favourite nights out in Newcastle since 2010!
        </h1>
        <SocialIcons />
      </Hero>
      <Fight />
      <Tickets />
      <Reps />
      <Gallery />
      <Partners />
      <Contact />
    </>
  );
}
